import React from "react";

import Layout from "../../../components/layout";
import { Link, useStaticQuery, graphql } from 'gatsby';
import ApplicationGuide from '../../resources/Application-Guide.pdf';
import Img from "gatsby-image";
import { ReactComponent as CheckIcon } from "../../../styles/icons/check-solid.svg";
import { Helmet } from 'react-helmet';
import data from '../../../data/air-filters';
import productsPageStyles from '../../../styles/productsPage.module.scss';
import pages from '../../../data/navItems'

export default function () {

    const { airFilters, farmer } = useStaticQuery(
        graphql`query airFiltersImg1 {
            airFilters: file(relativePath: {eq: "products-air-filters.png"}) {
                childImageSharp {
                    fluid(maxWidth: 650) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                    fixed(width: 400) {
                        ...GatsbyImageSharpFixed_tracedSVG
                    }
                }
            }
            farmer: file(relativePath: {eq: "farmer.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                    fixed(width: 400) {
                        ...GatsbyImageSharpFixed_tracedSVG
                    }
                }
            }
        }`
    );
    console.log(data);
    return <Layout>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Cabin Air Filters | Clean Air Filter</title>
        </Helmet>
        <article>
            <section>
                <h1><span className="tail"><span className="head">{data.content.section1.h1.head}</span> {data.content.section1.h1.tail}</span></h1>

                <article className="w-60">
                    <div className={productsPageStyles.floatRight} style={{ width: 500 }}>
                        <Img fluid={airFilters.childImageSharp.fluid} />
                    </div>
                    <p>{data.content.section1.p1}</p>
                    <ul style={{
                        listStyle: 'none'
                    }}>
                        <li dangerouslySetInnerHTML={{ __html: data.content.section1.ul.li1 }}></li>
                        <li dangerouslySetInnerHTML={{ __html: data.content.section1.ul.li2 }}></li>
                        <li dangerouslySetInnerHTML={{ __html: data.content.section1.ul.li3 }}></li>
                    </ul>
                    <p>{data.content.section1.p2}</p>
                </article>
            </section>
            <hr></hr>

            <section>
                <article className="w-60">
                    <h2>{data.content.section6.h2}</h2>
                    <p>{data.content.section6.p1}</p>


                    <div className={productsPageStyles.floatRight} style={{ width: 400, textAlign: "left", margin: '0 1rem' }}>
                        <Img fluid={farmer.childImageSharp.fluid} imgStyle={{ borderRadius: '0.5rem' }} />

                        <p className="cell" style={{ display: 'flex' }}><CheckIcon style={{ minWidth: 24 }}></CheckIcon>&#160;{data.content.floatRight.p1}</p>
                        <p className="cell" style={{ display: 'flex' }}><CheckIcon style={{ minWidth: 24 }}></CheckIcon>&#160;{data.content.floatRight.p2}</p>
                        <p className="cell" style={{ display: 'flex' }}><CheckIcon style={{ minWidth: 24 }}></CheckIcon>&#160;{data.content.floatRight.p3}</p>
                        <p className="cell" style={{ display: 'flex' }}><CheckIcon style={{ minWidth: 24 }}></CheckIcon>&#160;{data.content.floatRight.p4}</p>
                    </div>
                </article>
            </section>


            <section style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-start' }}>
                <p style={{ margin: '0 1rem 1rem 0' }}><a className="primary red" href="tel:+1.712.748.3642">Order Now 712.748.3642</a></p>
                <p style={{ margin: '0 1rem 1rem 0' }}><a className="download-link" href={ApplicationGuide}>Application Guide</a></p>
            </section>

            <section style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-start' }}>
                <p style={{ margin: '0 1rem 1rem 0' }}><Link className="primary red" to={pages[0].to + pages[0].children[1].to + pages[0].children[1].children[0].to}>View Limitations</Link></p>
                <p style={{ margin: '0 1rem 1rem 0' }}><Link className="primary red" to={pages[0].to + pages[0].children[1].to + pages[0].children[1].children[1].to}>View Maintenance</Link></p>
                <p style={{ margin: '0 1rem 1rem 0' }}><Link className="primary red" to={pages[0].to + pages[0].children[1].to + pages[0].children[1].children[2].to}>View Warranty</Link></p>
            </section>
        </article>
    </Layout>;
}
